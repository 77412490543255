<template>
  <div class="bg-main">
    <Header />

    <v-container class="px-6">
      <div class="profile-card mb-3">
        <v-layout align-center class="py-2">
          <v-flex class="mx-auto pl-8" xs3>
            <v-avatar size="56">
              <img alt="user" src="@/assets/image/profile.png" />
            </v-avatar>
          </v-flex>
          <v-flex class="mx-auto pl-8" xs9>
            <div style="font-weight: bold">
              {{ user.firstname + " " + user.lastname }}
            </div>
            <span>
              {{ user.phone_number }}
            </span>
          </v-flex>
        </v-layout>

        <v-divider></v-divider>

        <v-layout>
          <v-flex class="fill-height text-center pb-2 pt-1" xs6>
            <div style="font-weight: bold; font-size: 20px">
              {{ driver.truck_plate_no }}
            </div>
            <span class="text-gray-tnl">ทะเบียนหัวลาก</span>
          </v-flex>
          <v-divider vertical />
          <v-flex class="fill-height text-center pb-2 pt-1" xs6>
            <div style="font-weight: bold; font-size: 20px">
              {{ driver.tail_plate_no }}
            </div>
            <span class="text-gray-tnl"> ทะเบียนหาง </span>
          </v-flex>
        </v-layout>
      </div>

      <v-row>
        <v-col cols="6" class="pr-2">
          <card-work-main :data="workData1" />
        </v-col>
        <v-col cols="6" class="pl-2">
          <card-work-main :data="workData2" />
        </v-col>
      </v-row>
      <v-row class="pt-0">
        <v-col cols="6" class="pr-2 pt-0">
          <card-work-main :data="workData3" />
        </v-col>
        <v-col cols="6" class="pl-2 pt-0">
          <card-work-main :data="workData4" />
        </v-col>
      </v-row>
    </v-container>

    <div class="mt-4 px-6">
      <div class="mb-4">
        <v-btn class="button-tnl" color="#59CB33" @click="goto('report')">
          <img class="pr-3" src="@/assets/image/icon-report.png" />
          <span style="color: white; font-size: 32px">รายการงาน</span>
        </v-btn>
      </div>

      <div class="mb-6">
        <v-btn
          color="#62C4E3"
          style="width: 100%; border-radius: 15px; height: 47px"
          @click="goto('problem')"
        >
          <img class="pr-3" src="@/assets/image/icon-warning.png" />
          <span style="color: white; font-size: 16px">แจ้งปัญหา</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import CardWorkMain from "@/components/cardWorkMain";
import { mapState } from "vuex";
import Header from "../../components/header.vue";
export default {
  name: "main-page",
  components: { CardWorkMain, Header, Header },
  data: () => ({
    workData1: {
      image: "",
      amount: "",
      text: "",
      goto: "",
      footer: null,
      color: "#338A68",
    },
    workData2: {
      image: "",
      amount: "",
      text: "",
      goto: "",
      footer: null,
      color: "#59CB33",
    },
    workData3: {
      image: "",
      amount: "",
      text: "",
      goto: "",
      footer: "",
      footer_text: "",
      color: "#62C4E3",
    },
    workData4: {
      image: "",
      amount: "",
      text: "",
      goto: "",
      footer: "",
      footer_text: "",
      color: "#EC8235",
    },
  }),
  created() {
    this.globalLoading();
    this.$store.dispatch("common/home").then(() => {
      this.workData1.image = 1;
      this.workData1.amount = this.driver_jobs.job_complete_today;
      this.workData1.text = "งานเสร็จสิ้นวันนี้";
      this.workData1.goto = "report1";
      this.workData2.image = 2;
      this.workData2.amount = this.driver_jobs.job_new_today;
      this.workData2.text = "งานเข้าใหม่วันนี้";
      this.workData2.goto = "report2";
      this.workData3.image = 3;
      this.workData3.amount = this.driver_jobs.job_complete_all;
      this.workData3.goto = "report3";
      this.workData3.footer = true;
      this.workData3.footer_text = "สัปดาห์";
      this.workData3.text = "งานเสร็จสิ้นทั้งหมด";
      this.workData4.image = 4;
      this.workData4.amount = this.driver_jobs.job_not_complete_all;
      this.workData4.goto = "report4";
      this.workData4.footer = true;
      this.workData4.footer_text = "เดือน";
      this.workData4.text = "งานที่ยังไม่เสร็จทั้งหมด";
      this.globalHideLoading();
    });
  },
  computed: {
    ...mapState({
      user: (state) => {
        return state.auth.user;
      },
      driver: (state) => {
        return state.common.driver;
      },
      driver_jobs: (state) => {
        return state.common.driver_jobs;
      },
    }),
  },
  methods: {
    goto(param) {
      this.$router.push({
        path: param,
        query: {
          filter: "NEW",
          sort: "ทั้งหมด",
          show: "0",
        },
      });
      // this.$router.push(param);
    },
  },
};
</script>

<style scoped>
span {
  color: black;
}
</style>
