<template>
  <div class="card-work-main-tnl text-center pb-0" @click="goto(data.goto)">
    <div class="py-4">
      <img
        class="break-line"
        v-if="data.image == 1"
        src="@/assets/image/icon-work1.png"
      />
      <img
        class="break-line"
        v-else-if="data.image == 2"
        src="@/assets/image/icon-work2.png"
      />
      <img
        class="break-line"
        v-else-if="data.image == 3"
        src="@/assets/image/icon-work3.png"
      />
      <img
        class="break-line"
        v-else-if="data.image == 4"
        src="@/assets/image/icon-work4.png"
      />
      <div
        class="break-line mt-n3"
        style="font-size: 54px; font-weight: bold"
        :style="{ color: data.color }"
      >
        {{ data.amount ? data.amount : 0 }}
      </div>

      <div class="text-gray-tnl mt-n3 mb-1">
        {{ data.text }}
      </div>

      <div v-if="data.footer" @click="gotoWith(data.goto)">
        <v-divider />
        <div class="text-center pt-2">
          <v-row>
            <v-col class="pt-2 pb-1">
              <span>
                {{ data.footer_text }}
              </span>
              <img
                style="position: relative; top: 3px"
                src="@/assets/image/dropdown-green.png"
              />
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cardWorkMain",
  props: {
    data: Object,
  },
  data() {
    return {
      isClick: false,
    };
  },
  methods: {
    goto(param) {
      if (!this.isClick) {
        if (param == "report1")
          this.$router.push(`report?filter=COMPLETE&sort=TODAY&show=3`);
        else if (param == "report2")
          this.$router.push(`report?filter=NEW&sort=TODAY&show=`);
        else if (param == "report3")
          this.$router.push(`report?filter=COMPLETE&sort=&show=3`);
        else if (param == "report4")
          this.$router.push(`report?filter=PROGRESS&sort=&show=2`);
      }
    },
    gotoWith(param) {
      this.isClick = true;
      if (param == "report3")
        this.$router.push(`report?filter=COMPLETE&sort=WEEK&show=3`);
      else if (param == "report4")
        this.$router.push(`report?filter=PROGRESS&sort=MONTH&show=4`);
    },
  },
};
</script>

<style scoped></style>
